<template>
    <div>
        <div class="news-banner"></div>
        <div class="news-main">
            <div class="news-title">
                当前位置：首页》更多资讯
                <i></i>
            </div>
            <div class="news-article">
                <div class="articleBox">
                    <div class="contLeft" v-for="item in all" :key="item.id" @click="goNews(item.title,item.cover_url,item.content)">
                        <figure> <img :src="item.cover_url" alt=""> </figure>
                        <figcaption>
                            <h6>{{item.title}}</h6>
                            <span>{{item.updatedAt.date.split('.')[0]}}</span>
                        </figcaption>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getArticle } from '../../aips/article'
export default {
    data(){
        return{
            all:'',
            allData:''
        }
    },
    async created(){
        let res = await getArticle({type_name:'INDUSTRY'})
        this.all = res.data
        console.log(this.all)
        console.log(this.allData)
        this.changeHash('#container')
    },
    methods:{
        changeHash(idname) {
            document.querySelector(idname).scrollIntoView(true);
        },
        //跳转资讯详情页
        async goNews(title,image,content){
            this.$store.commit("common/mutationTitle",title)
            this.$store.commit("common/mutationImage",image)
            this.$store.commit("common/mutationContent",content)
            this.$router.push('/news')
        },
    }
}

</script>

<style lang="less" scoped>
    @import '../../style/newsCenter.less';
</style>